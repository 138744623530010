<template>
  <div style="padding: 10px">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="20">
            <a-col :md="4" :sm="24">
              <a-form-item label="产线/仓">
                <a-input v-model="queryParam.productLine" placeholder="请输入产线/仓" allowClear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="巡查项目">
                <a-input v-model="queryParam.questionName" placeholder="请输入巡查项目" allowClear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="巡查结论">
                <a-input v-model="queryParam.resultStatus" placeholder="请输入巡查结论" allowClear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="提交人员">
                <a-input v-model="queryParam.submitUserName" placeholder="请输入提交人员" allowClear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="提交时间">
                <a-range-picker v-model="submitTimes" @change="changeDate" :allowClear="false"/>
              </a-form-item>
            </a-col>
            <a-col :md="3" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" :loading="downloadLoading" @click="exportExcel(false)" icon="export">导出本页</a-button>
        <a-button type="primary" :loading="downloadLoading"@click="exportExcel(true)" icon="export">导出所有</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :columns="columns"
        :data="loadData"
        :scroll="{y: true}"
        :rowKey="(record) => record.id"
        :pagination="{pageSizeOptions: ['10', '50', '100', '500', '1000'], showTotal: total => `共 ${total} 条`}"
      >
        <span slot="resultFile" slot-scope="resultFile">
          <a-avatar class="result-file-avatar" v-for="url in resultFile" :icon="getFileIcon(url)" shape="square" @click="viewFile(url)" />
        </span>
      </s-table>
    </a-card>

    <a-modal :visible="viewModal.visible" :footer="null" @cancel="viewModal.visible = !viewModal.visible" :destroyOnClose="true">
      <img v-if="isImage(viewModal.url)" alt="" style="width: 100%" :src="getFileUrl(viewModal.url)" />
      <video v-else-if="isVideo(viewModal.url)" alt="" controls="controls" autoplay="autoplay" style="width: 100%">
        <source :src="getFileUrl(viewModal.url)" type="video/mp4"/>
      </video>
      <div v-else style="text-align: center; font-size: 18px" v-else>该文件暂不支持查看！</div>
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { listPatrolStuffReports, exportPatrolStuffReports } from '@/api/reports/patrolReports'
import {isImage, isVideo} from '@/utils/mediaUtil'

export default {
  components: {
    STable
  },

  data () {
    return {
      downloadLoading: false,
      advanced: false, // 高级搜索 展开/关闭
      submitTimes: [],
      queryParam: {
        pageNum: 1,
        pageSize:10
      }, // 查询参数
      columns: [ // 表头
        {
          title: '产线/仓',
          dataIndex: 'productLine'
        },
        {
          title: '巡查项目',
          dataIndex: 'questionName'
        },
        {
          title: '巡查结论',
          dataIndex: 'resultStatus'
        },
        {
          title: '情况说明',
          dataIndex: 'resultMsg'
        },
        {
          title: '图片/视频',
          dataIndex: 'resultFile',
          scopedSlots: { customRender: 'resultFile' }
        },
        {
          title: '提交人员',
          dataIndex: 'submitUserName'
        },
        {
          title: '提交时间',
          dataIndex: 'submitTime'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.queryParam.pageNum = parameter.pageNo
        this.queryParam.pageSize = parameter.pageSize
        return listPatrolStuffReports(this.queryParam).then((res) => {
          res.data = res['rows']
          res.totalCount = res['total']
          res.pageNo = parameter['pageNo']
          return res
        })
      },
      viewModal: {
        visible: false,
        url: ''
      }
    }
  },
  created() {
    this.initDate()
  },
  methods: {
    isImage,
    isVideo,
    reset() {
      this.initDate()
      this.$refs.table.refresh(true)
    },
    initDate() {
      const startTime = this.moment().startOf('week').format ('YYYY-MM-DD')
      const endTime = this.moment().endOf('week').format ('YYYY-MM-DD')
      this.queryParam = {startTime, endTime}
      this.submitTimes = [startTime, endTime]
    },
    changeDate(date) {
      this.queryParam.startTime = date[0].format('YYYY-MM-DD')
      this.queryParam.endTime = date[1].format('YYYY-MM-DD')
    },
    exportExcel(isAll) {
      this.downloadLoading = true
      if (isAll) {
        this.queryParam.pageNum = 0
        this.queryParam.pageSize = this.queryParam.pageSize > 1000 ? 1000 : 0
      }
      exportPatrolStuffReports(this.queryParam).then(res => {
        const url = URL.createObjectURL(res)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', '铲车巡检记录' + this.moment(new Date()).format('YYYYMMDDhhmmssSSS'))
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
      }).finally(() => {
        this.downloadLoading = false
      })
    },
    getFileUrl(src) {
      return process.env.VUE_APP_API_FILE_URL + src
    },
    viewFile(url) {
      this.viewModal.visible = true
      this.viewModal.url = url
    },
    getFileIcon(url) {
      if (url) {
        if (isImage(url)) {
          return 'picture'
        }
        if (isVideo(url)) {
          return 'video-camera'
        }
      }
      return 'question-circle'
    }



  }
}
</script>

<style lang="less" scope>

.table-operator {
  margin-bottom: 5px;
}

.result-file-avatar {
  margin-right: 5px;
  background-color: transparent;
  color: #42b983;
  border: 1px dashed #42b983;
  cursor: pointer;
}

button {
  margin-right: 8px;
}


.ant-table .ant-table-body{
  max-height: calc(100vh - 350px);
}

</style>
