const isImage = (name) => {
  if(name) {
    const imageType = ["gif", "jpeg", "jpg", "bmp", "png"]
    return RegExp("\.(" + imageType.join("|") + ")$", "i").test(name.toLowerCase())
  }
  return false
}

const isVideo = (name) => {
  if(name) {
    const videoType = ["avi","wmv","mkv","mp4","mov","rm","3gp","flv","mpg","rmvb"]
    return RegExp("\.(" + videoType.join("|") + ")$", "i").test(name.toLowerCase())
  }
  return false
}

export {
  isImage,
  isVideo
}