import { axios } from '@/utils/request'

/**
 * 获取设备巡检记录列表
 * @param params
 * @return
 */
export function listPatrolDeviceReports(params) {
  return axios({
    url: '/patrol_reports/list_patrol_device_reports',
    method: 'get',
    params: params
  })
}

/**
 * 导出设备巡检记录列表
 * @param params
 */
export function exportPatrolDeviceReports(params) {
  return axios({
    url: '/patrol_reports/export_patrol_device_reports',
    method: 'get',
    params: params,
    responseType: 'blob',
    timeout: 60000
  })
}

/**
 * 获取铲车巡检记录列表
 * @param params
 * @return
 */
export function listPatrolCarReports(params) {
  return axios({
    url: '/patrol_reports/list_patrol_car_reports',
    method: 'get',
    params: params
  })
}

/**
 * 导出铲车巡检记录列表
 * @param params
 */
export function exportPatrolCarReports(params) {
  return axios({
    url: '/patrol_reports/export_patrol_car_reports',
    method: 'get',
    params: params,
    responseType: 'blob',
    timeout: 60000
  })
}

/**
 * 获取材料巡检记录列表
 * @param params
 * @return
 */
export function listPatrolStuffReports(params) {
  return axios({
    url: '/patrol_reports/list_patrol_stuff_reports',
    method: 'get',
    params: params
  })
}

/**
 * 导出材料巡检记录列表
 * @param params
 */
export function exportPatrolStuffReports(params) {
  return axios({
    url: '/patrol_reports/export_patrol_stuff_reports',
    method: 'get',
    params: params,
    responseType: 'blob',
    timeout: 60000
  })
}